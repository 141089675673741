<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import eventBus from '@/lenon/utils/eventBus'
import showToast from '@/lenon/mixins/showToast'
import { initialAbility } from '@/libs/acl/config'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  mixins: [
    showToast,
  ],
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  mounted() {
    if (window.Cypress) { // only when testing
      window.lenon = this
    }
    const skin = localStorage.getItem('vuexy-skin')
    const body = document.getElementById('lenon-body')
    if (skin === 'dark') {
      body.setAttribute('id', 'lenon-body-dark')
    } else {
      body.setAttribute('id', 'lenon-body-light')
    }
    document.addEventListener('focusin', e => {
      if (e.target.closest('.flatpickr-calendar') !== null) {
        e.stopImmediatePropagation()
      }
    })
    eventBus.$on('requestError', category => {
      if (category === 'inactive' || category === 'refresh_token_failed') {
        this.$ability.update(initialAbility)
        this.showError('Your session has expired, please logout.')
        if (this.$store.getters['auth/accessToken']) {
          this.$store.commit('auth/setLoggedOut')
          this.$router.push({ name: 'login' }).then(() => {
            this.$store.commit('auth/clearAuthState')
          })
        } else {
          this.$router.push({ name: 'affiliate-login' }).then(() => {
            this.$store.commit('affiliate/clearAuth')
          })
        }
      }
      if (category === 'validation') {
        this.showError('Invalid data provided')
      }
      if (category === 'academic_year_completion') {
        this.showInfo('Academic Year is being completed, wait a bit longer and try again. Contact support if you still see this message after sometime')
      }
      if (category === 'internal') {
        this.showError('An error occurred, please try again or contact support')
      }
      if (category === 'login_failure') {
        this.showError('Invalid email or password')
      }
      if (category === 'use_reset_button') {
        this.showInfo('The specified academic year & term are the same as the current ones, please change them.')
      }
      if (category === 'invalid_academic_year') {
        this.$store.commit('auth/setCurrentModule', 'administration')
        this.showInfo('You have to setup an academic year')
        this.$router.push({ name: 'terms-and-classes' })
      }
      if (category === 'subscription_ended') {
        this.showInfo('Your subscription has ended please subscribe to continue using Lenon')
        this.$store.commit('auth/setCurrentModule', 'accounts')
        this.$router.push({ name: 'subscription' })
      }
      if (category === 'not_verified') {
        this.showInfo('This wallet has not been verified yet.')
        this.$store.commit('auth/updateSchool', { verified: false })
        this.$router.push({ name: 'unverified' })
      }
      if (category === 'module_not_available') {
        this.showInfo('Your school does not have access to this module.')
        this.$router.push({ name: 'home' })
      }
      if (category === 'multiple_active_terms') {
        this.showInfo('Please select a term')
        this.$router.push({ name: 'active-terms' })
      }
      if (category === 'must_be_a_promotional_term') {
        this.showInfo('Make sure you are in a promotional term')
      }
      if (category === 'no_more_than_one_active_terms') {
        this.showInfo('You do not have more than one active terms.')
        this.$router.push({ name: 'home' })
      }
      if (category === 'no_more_than_one_active_account') {
        this.showInfo('You do not have more than one active accounts.')
        this.$router.push({ name: 'home' })
      }
      if (category === 'multiple_accounts') {
        this.showInfo('You are associated to more than one account')
        this.$router.push({ name: 'active-accounts' })
      }
      if (category === 'insufficient_report_credits') {
        this.showInfo('Freemium users are expected to pay for report generation, please buy some credits or upgrade your account.')
        // this.$router.push({ name: 'active-accounts' })
      }
    })
  },
  destroyed() {
    eventBus.$off('requestError')
  },
}
</script>
