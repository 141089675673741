import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import eventBus from '@/lenon/utils/eventBus'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})
function refreshToken() {
  if (store.state.auth.isRefreshing) {
    return store.state.auth.refreshingCall
  }
  store.commit('auth/setRefreshingState', true)
  const refreshingCall = axiosIns.get(`auth/refresh-token?refresh_token=${store.getters['auth/refreshToken']}`).then(res => {
    store.commit('auth/updateToken', res.data)
    store.commit('auth/setRefreshingState', false)
    store.commit('auth/setRefreshingCall', undefined)
    return Promise.resolve(true)
  })
  store.commit('auth/setRefreshingCall', refreshingCall)
  return refreshingCall
}
axiosIns.interceptors.request.use(config => {
  config.headers.Accept = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  config.headers.Authorization = `Bearer ${store.getters['auth/accessToken'] || store.getters['affiliate/accessToken']}`
  config.headers['X-Is-Affiliate'] = store.getters['affiliate/accessToken'] ? 'yes' : 'no'
  config.headers['X-User-Type'] = 'Teacher'
  return config
})
axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    return refreshToken(store).then(_ => {
      error.config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`
      return axiosIns.request(error.config)
    }).catch(err => {
      eventBus.$emit('requestError', 'refresh_token_failed')
    })
  }
  if (error.response.status === 444) {
    eventBus.$emit('requestError', error.response.data)
  }
  return Promise.reject(error)
})
Vue.prototype.$http = axiosIns

export default axiosIns
