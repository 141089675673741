// eslint-disable-next-line import/prefer-default-export
export const markers = {
  namespaced: true,
  state: {
    markers: [],
    settings: {
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
    },
    markedSettings: {
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      selectedMarkerId: null,
    },
    markedAssessments: [],
  },
  mutations: {
    setMarkedAssessments(state, data) {
      state.markedAssessments = data
    },
    setMarkedSettings(state, data) {
      state.markedSettings = { ...state.markedSettings, ...data }
    },
    setMarkerSettings(state, data) {
      state.settings = { ...state.settings, ...data }
    },
    setMarkers(state, data) {
      state.markers = data
    },
    addMarker: (state, marker) => {
      state.markers = [marker, ...state.markers]
    },
    updateMarker: (state, marker) => {
      state.markers = state.markers.map(datum => {
        if (datum.id === marker.id) {
          return marker
        }
        return datum
      })
    },
    removeMarker: (state, id) => {
      state.markers = state.markers.filter(datum => datum.id !== id)
    },
  },
  getters: {
    markers(state) {
      return state.markers
    },
    markerSettings(state) {
      return state.settings
    },
    markedSettings(state) {
      return state.markedSettings
    },
    markedAssessments(state) {
      return state.markedAssessments
    },
  },
}
