export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/affiliate-login',
    name: 'affiliate-login',
    component: () => import('@/views/auth/AffiliateLogin.vue'),
    meta: {
      layout: 'full',
      affiliate: true,
      redirectIfLoggedIn: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/affiliate-register',
    name: 'affiliate-register',
    component: () => import('@/views/auth/AffiliateRegister.vue'),
    meta: {
      layout: 'full',
      affiliate: true,
      redirectIfLoggedIn: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/active-terms',
    name: 'active-terms',
    component: () => import('@/views/auth/Terms.vue'),
    meta: {
      layout: 'full',
      redirectIfGuest: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/active-accounts',
    name: 'active-accounts',
    component: () => import('@/views/auth/Accounts.vue'),
    meta: {
      layout: 'full',
      redirectIfGuest: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/auth/Profile.vue'),
    meta: {
      redirectIfGuest: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/auth/Reset.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'full',
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
]
