import http from '@/libs/axios'

export const documents = {
  namespaced: true,
  state: {
    books: [],
    schoolCredits: {input_credits:0,output_credits:0},
    files: [],
    trashFiles: [],
    credits: [],
    questions: [],
    onlineExams: [],
    settings: {
      currentFolder: null,
      sortColumn: null,
      sortDirection: null,
    },
    questionSettings: {
      selectedAcademicYearId: null,
      selectedTermId: null,
    },
  },
  mutations: {
    setOnlineExams: (state, onlineExams) => {
      state.onlineExams = onlineExams
    },
    addOnlineExam: (state, onlineExam) => {
      state.onlineExams = [onlineExam, ...state.onlineExams]
    },
    updateOnlineExam: (state, credit) => {
      state.onlineExams = state.onlineExams.map(datum => {
        if (datum.id === credit.id) {
          return credit
        }
        return datum
      })
    },
    removeOnlineExam: (state, id) => {
      state.onlineExams = state.onlineExams.filter(datum => id === datum.id)
    },
    setCredits: (state, credits) => {
      state.credits = credits
    },
    setSchoolCredits: (state, credits) => {
      state.schoolCredits = credits
    },
    addCredits: (state, credits) => {
      state.credits = [...credits, ...state.credits]
    },
    updateCredit: (state, credit) => {
      state.credits = state.credits.map(datum => {
        if (datum.id === credit.id) {
          return credit
        }
        return datum
      })
    },
    removeCredit: (state, id) => {
      state.credits = state.credits.filter(datum => id === datum.id)
    },
    setQuestions: (state, questions) => {
      state.questions = questions
    },
    setBooks: (state, books) => {
      state.books = books
    },
    addBook: (state, book) => {
      state.books = [book, ...state.books]
    },
    removeBook: (state, id) => {
      state.books = state.books.filter(datum => id !== datum.id)
    },
    setQuestionSettings: (state, settings) => {
      state.questionSettings = { ...state.questionSettings, ...settings }
    },
    setSettings: (state, settings) => {
      state.settings = { ...state.settings, ...settings }
    },
    setTrashFiles: (state, files) => {
      state.trashFiles = files
    },
    setFiles: (state, files) => {
      state.files = files
    },
    addFile: (state, file) => {
      state.files = [file, ...state.files]
    },
    addFiles: (state, files) => {
      state.files = [...files, ...state.files]
    },
    updateFile: (state, file) => {
      state.files = state.files.map(datum => {
        if (datum.id === file.id) {
          return file
        }
        return datum
      })
    },
    updateBook: (state, file) => {
      state.books = state.books.map(datum => {
        if (datum.id === file.id) {
          return file
        }
        return datum
      })
    },
    removeFiles: (state, ids) => {
      state.files = state.files.filter(datum => !ids.includes(datum.id))
    },
    removeTrashFiles: (state, ids) => {
      state.trashFiles = state.trashFiles.filter(datum => !ids.includes(datum.id))
    },
  },
  actions: {
    createNotes({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/notes', data).then(res => {
          commit('addFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    createFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        let url = 'create'
        if (data.folderId) {
          url = `${url}?folder_id=${data.folderId}`
        }
        http.post(`documents/files/${url}`, data.file).then(res => {
          commit('addFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.put(`documents/files/${data.id}`, data).then(res => {
          commit('updateFile', res.data)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteFiles({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post(`documents/files/delete?delete_permanently=${data.permanent}`, { ids: data.ids }).then(res => {
          commit('removeFiles', data.ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteFromTrash({ commit }, ids) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/delete?from_trash=true', { ids }).then(res => {
          commit('removeTrashFiles', ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    restoreFiles({ commit }, ids) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/restore', { ids }).then(res => {
          commit('removeTrashFiles', ids)
          resolve(true)
        }).catch(err => {
          reject(err)
        })
      })
    },
    uploadFiles({ commit }, files) {
      return new Promise((resolve, reject) => {
        http.post(`documents/files/${files.id}`, files.data).then(res => {
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchFiles({ commit }, folderId) {
      return new Promise((resolve, reject) => {
        let url = 'files'
        if (folderId) {
          url = `${url}?folder_id=${folderId}`
        }
        http.get(`documents/${url}`)
          .then(res => {
            commit('setFiles', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchBooks({ commit }) {
      return new Promise((resolve, reject) => {
        http.get('documents/books')
          .then(res => {
            commit('setBooks', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchOnlineExams({ commit }) {
      return new Promise((resolve, reject) => {
        http.get('documents/online-exams')
          .then(res => {
            commit('setOnlineExams', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getTrashFiles({ commit }) {
      return new Promise((resolve, reject) => {
        http.get('documents/files/trash')
          .then(res => {
            commit('setTrashFiles', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getUser({ commit }, search) {
      return new Promise((resolve, reject) => {
        http.get(`documents/user-to-share-with?search_term=${search}`)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    downloadAssessment({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`documents/download-assessment/${id}`)
          .then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
      })
    },
    fetchQuestions({ commit }, query) {
      return new Promise((resolve, reject) => {
        http.get(`documents/generated-questions?term_id=${query.term}&ay_id=${query.ay}`)
          .then(res => {
            commit('setQuestions', res.data)
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      })
    },
    shareFiles({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.post('documents/files/share', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getContributionSummary({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.get('dashboard/contribution-summary', data)
          .then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {
    files(state) {
      return state.files
    },
    trashFiles(state) {
      return state.trashFiles
    },
    settings(state) {
      return state.settings
    },
    books(state) {
      return state.books
    },
    questions(state) {
      return state.questions
    },
    questionSettings(state) {
      return state.questionSettings
    },
    credits(state) {
      return state.credits
    },
    schoolCredits(state) {
      return state.schoolCredits
    },
    onlineExams(state) {
      return state.onlineExams
    },
  },
}
