export const affiliate = {
  namespaced: true,
  state: {
    auth: {
      user: {},
      token: null,
    },
    bank: {},
    banks: [],
    total_revenue: 0.0,
    payments: [],
    school: null,
  },
  mutations: {
    setAuth(state, login) {
      state.auth.user = login.user
      state.auth.token = login.token
    },
    setSchool(state, school) {
      state.school = school
    },
    setPayments(state, payments) {
      state.payments = payments
    },
    setBankDetails(state, data) {
      state.banks = data.banks
      state.bank = data.bank
    },
    clearAuth(state) {
      state.auth = {
        user: {},
        token: null,
      }
    },
    updatePhoto(state, data) {
      state.auth.user.photo = data
    },
    updateProfile(state, data) {
      state.auth.user = { ...state.auth.user, ...data }
    },
    updateBank(state, data) {
      state.bank = { ...state.bank, ...data }
    },
  },
  actions: {
  },
  getters: {
    bank(state) {
      return state.bank
    },
    banks(state) {
      return state.banks
    },
    totalRevenue(state) {
      return state.total_revenue
    },
    payments(state) {
      return state.payments
    },
    role(state) {
      return 'Affiliate'
    },
    school(state) {
      return state.school
    },
    accessToken(state) {
      return state.auth?.token
    },
    user(state) {
      return state.auth.user
    },
    userProfile(state) {
      return state.auth.user?.photo ? `${state.auth.user?.photo}` : null
    },
  },
}
