export default [
  {
    path: '/finance',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'fee-settings',
        name: 'fee-settings',
        component: () => import('@/views/finance/fee-settings/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-fee-settings',
        },
      },
      {
        path: 'fee-payments',
        name: 'fee-payments',
        component: () => import('@/views/finance/fee-payments/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-fee-payments',
        },
      },
      {
        path: 'fee-receipts',
        name: 'fee-receipts',
        component: () => import('@/views/finance/fee-receipts/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-fees-and-receipts',
        },
      },
      {
        path: 'arrears-list',
        name: 'arrears-list',
        component: () => import('@/views/finance/arrears-list/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-arrears-list',
        },
      },
      {
        path: 'recurring-fees',
        name: 'recurring-fees',
        component: () => import('@/views/finance/recurring-fees/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-recurring-fees',
        },
      },
      {
        path: 'other-income',
        name: 'other-income',
        component: () => import('@/views/finance/other-income/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-other-income',
        },
      },
      {
        path: 'expenses',
        name: 'expenses',
        component: () => import('@/views/finance/expenses/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-expenses',
        },
      },
      {
        path: 'fee-projections',
        name: 'fee-projections',
        component: () => import('@/views/finance/fee-projections/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'finance',
          action: 'access-fee-projections',
        },
      },
      // {
      //   path: 'transfers',
      //   name: 'transfers',
      //   component: () => import('@/views/finance/transfers/Index.vue'),
      //   meta: {
      //     redirectIfGuest: true,
      //     resource: 'finance',
      //     action: 'access-transfers',
      //   },
      // },
      {
        path: 'guide',
        name: 'finance-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]
