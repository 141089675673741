export default [
  {
    path: '/dashboard',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'finance',
        name: 'finance',
        component: () => import('@/views/dashboard/finance/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-finance-dashboard',
        },
      },
      // academics
      {
        path: 'students',
        name: 'students',
        component: () => import('@/views/dashboard/students/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-students-dashboard',
        },
      },
      // {
      //   path: 'academics',
      //   name: 'academics',
      //   component: () => import('@/views/dashboard/academics/Index.vue'),
      //   meta: {
      //     redirectIfGuest: true,
      //     resource: 'dashboard',
      //     action: 'access-academics-dashboard',
      //   },
      // },
      {
        path: 'transportation',
        name: 'transportation',
        component: () => import('@/views/dashboard/transportation/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'dashboard',
          action: 'access-transportation-dashboard',
        },
      },
      {
        path: 'guide',
        name: 'dashboard-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
  {
    path: '/affiliate-dashboard',
    name: 'affiliate',
    component: () => import('@/views/dashboard/affiliate/Index.vue'),
    meta: {
      redirectIfGuest: true,
      layout: 'full',
      affiliate: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
  {
    path: '/school-payments',
    name: 'school-payments',
    component: () => import('@/views/dashboard/affiliate/SchoolPayments.vue'),
    meta: {
      redirectIfGuest: true,
      layout: 'full',
      affiliate: true,
      resource: 'lenon',
      action: 'access-lenon-app',
    },
  },
]
