export default [
  {
    path: '/smartmarker',
    component: () => import('@/layouts/GeneralLayout.vue'),
    children: [
      {
        path: 'marker-settings',
        name: 'marker-settings',
        component: () => import('@/views/smartmarker/marker-settings/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'smartmarker',
          action: 'access-marker-settings',
        },
      },
      // academics
      {
        path: 'marked-booklets',
        name: 'marked-booklets',
        component: () => import('@/views/smartmarker/marked/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'accounts',
          action: 'access-marked-booklets',
        },
      },
      {
        path: 'guide',
        name: 'smartmarker-guide',
        component: () => import('@/views/admin/Index.vue'),
        meta: {
          redirectIfGuest: true,
          resource: 'lenon',
          action: 'access-lenon-app',
        },
      },
    ],
  },
]
